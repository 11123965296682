import { useEffect, useState, useCallback } from 'react';
import { isBrowser } from '@agria/utils';

const useJourneyTracker = (): {
  journeyNumber: number;
  incrementJourneyNumber: () => void;
  removeJourneyNumber: () => void;
} => {
  const [journeyNumber, setJourneyNumber] = useState<number>(0);

  useEffect(() => {
    if (isBrowser) {
      const storedJourneyNumber = sessionStorage.getItem('journey_number');
      if (storedJourneyNumber === null) {
        sessionStorage.setItem('journey_number', '0');
      } else {
        setJourneyNumber(parseInt(storedJourneyNumber, 10));
      }
    }
  }, []);

  const incrementJourneyNumber = useCallback(() => {
    if (isBrowser) {
      const currentNumber = parseInt(
        sessionStorage.getItem('journey_number') || '0',
        10
      );
      const newNumber = currentNumber + 1;
      sessionStorage.setItem('journey_number', newNumber.toString());
      setJourneyNumber(newNumber);
    }
  }, []);

  const removeJourneyNumber = useCallback(() => {
    if (isBrowser) {
      setJourneyNumber(0);
      sessionStorage.removeItem('journey_number');
    }
  }, []);

  return { journeyNumber, incrementJourneyNumber, removeJourneyNumber };
};

export { useJourneyTracker };
