import { useCallback } from 'react';
import { RequestedQuotes } from '../context/QuoteContext';

interface GetDataLayerContentTypeProps {
  requestedQuotes?: RequestedQuotes | null;
  subSpecies?: string;
  journey?: string;
}

const useGetDataLayerContentType = () => {
  const getDataLayerContentType = useCallback(
    (params: GetDataLayerContentTypeProps | null = null) => {
      // Handle null params case
      if (!params) {
        return 'single pet';
      }

      const { requestedQuotes, journey } = params;

      // Handle litter capture journey
      if (journey === 'litter_capture') {
        return 'litter';
      }

      // Handle invalid or empty requestedQuotes
      if (!requestedQuotes || typeof requestedQuotes !== 'object') {
        return 'single pet';
      }

      // Calculate total number of pets
      const totalPets = Object.values(requestedQuotes).reduce(
        (acc, speciesData) => acc + (speciesData?.count || 0),
        0
      );

      return totalPets > 1 ? 'multipet' : 'single pet';
    },
    []
  );

  return getDataLayerContentType;
};

export default useGetDataLayerContentType;
