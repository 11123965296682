import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const BlankCircleFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 3.5c-7.17 0-13 5.83-13 13s5.83 13 13 13 13-5.83 13-13-5.83-13-13-13Z"
      clipRule="evenodd"
    />
  </Icon>
);
