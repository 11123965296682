import { PageProps, navigate } from 'gatsby';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSiteCountry } from '@agria/theme/src/hooks/useSiteCountry';
import * as Sentry from '@sentry/gatsby';
import {
  indexPage,
  indexStep,
  stepToPageMap as stepToPageMapPetFirst,
  stepToPageMapCustomerFirst,
  topLevelSteps as topLevelStepsStandard,
  topLevelStepsIE,
} from '../const';
import { useSpeciesInfo } from '../hooks/useSpeciesInfo';
import { FormSteps } from '../types/global';
import { FormPages } from '../types/steps';
import { QuoteContext } from './QuoteContext';

interface ActiveStepContextProviderProps {
  children: React.ReactNode;
  location: PageProps['location'];
}

interface ActiveStepContextProps {
  activeStep: FormSteps;
  setActiveStep: (step: FormSteps, message?: string) => void;
}

const removeHashFromString = (str: string) => str.replace(/^#/, '');

export const ActiveStepContext = createContext({} as ActiveStepContextProps);
ActiveStepContext.displayName = 'ActiveStepContext';

export const ActiveStepProvider = ({
  children,
  location,
}: ActiveStepContextProviderProps) => {
  const { activeSpecies } = useContext(QuoteContext);
  const speciesInfo = useSpeciesInfo(activeSpecies!);
  const firstPageLoad = useRef(true);
  const { isIe } = useSiteCountry();

  const topLevelSteps = isIe ? topLevelStepsIE : topLevelStepsStandard;

  let stepToPageMap = stepToPageMapPetFirst;

  if (isIe) {
    stepToPageMap = stepToPageMapCustomerFirst;
  }

  const findFirstStepByPage = useCallback(
    (formPage: FormPages) => {
      // Remove activity level from steps if not equine

      const modifiedMap =
        speciesInfo?.name !== 'Equine'
          ? Object.fromEntries(
              Object.entries(stepToPageMap).filter(
                ([step]) => step !== 'activityLevel'
              )
            )
          : stepToPageMap;

      return (
        Object.entries(modifiedMap)
          // Find the first key-value pair where the value matches the input
          .find(([, page]) => page === formPage)?.[0] as FormSteps
      ); // Return the key from the key-value pair
    },
    [speciesInfo?.name]
  );

  const defaultStep: FormSteps =
    findFirstStepByPage(location.pathname.replace(/\//g, '') as FormPages) ||
    indexStep;

  const [activeStep, setActiveStepFnc] = useState<FormSteps>(defaultStep);

  // Function to set the active step and if we need to use a hash
  const setActiveStep = useCallback(
    (step: FormSteps, message?: string) => {
      // We do not want to run this effect if the requested page is the same as current
      // console.log({ topLevelSteps, stepToPageMap, step });
      if (step && step !== activeStep) {
        const currentPage = stepToPageMap[step];
        const previousPage = stepToPageMap[activeStep || indexStep];

        try {
          Sentry.setTag('agria_journey_step', step);
          Sentry.addBreadcrumb({
            category: 'step',
            message: `Step changed to ${step} on page ${currentPage} from ${previousPage}`,
            level: 'info',
          });
        } finally {
        }

        // If the step is a valid step and the previousStep is not the step
        if (
          currentPage &&
          previousPage &&
          currentPage !== previousPage &&
          topLevelSteps &&
          topLevelSteps.includes(step)
        ) {
          // then we want to navigate to the appropriate page
          //  console.log('[NAV] nextStep without hash', currentPage);
          navigate(`/${currentPage !== indexPage ? currentPage : ''}`, {
            state: {
              step,
              message,
            },
          });
        } else {
          const nextStep = `${currentPage}#${step}`;
          //   console.log('[NAV] nextStep with hash', nextStep);
          // navigate with hash
          navigate(`/${currentPage !== indexPage ? nextStep : ''}`, {
            state: {
              step,
              message,
            },
          });
        }
        // Update the previousStepRef to the current step
        // this gets picked up by the location effect below
        // setActiveStepFnc(step);
      }
    },
    [activeStep]
  );

  // We need to check the hash on page load to see if we need to update the activeStep
  useEffect(() => {
    const { pathname: currentPage, hash } = location;
    const currentHash = removeHashFromString(hash);
    const getPageStep: FormSteps =
      (currentHash as FormSteps) ||
      findFirstStepByPage(currentPage.replace(/\//g, '') as FormPages) ||
      indexStep;
    if (getPageStep !== activeStep) {
      // console.log(`[NAV] Step from Location: ${activeStep} >> ${getPageStep}`);
      setActiveStepFnc(getPageStep);
    }
    firstPageLoad.current = false;
  }, [activeStep, findFirstStepByPage, location, setActiveStep, firstPageLoad]);

  // Return values for context memoized
  const contextValues = useMemo(
    () => ({ activeStep, setActiveStep }),
    [activeStep, setActiveStep]
  );

  return (
    <ActiveStepContext.Provider value={contextValues}>
      {children}
    </ActiveStepContext.Provider>
  );
};
