export function numberToWord(number: number): string {
  // Create an array of words
  const words: string[] = [
    'zero',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
    'twentieth',
  ];

  // If the number is in the range 0..20, return the word
  if (number >= 0 && number <= 20) {
    return words[number];
  }
  // Otherwise, return the number as a string
  return number.toString();
}
